<template>
    <section>
        <div v-if="detalle.id" class="row mx-0 border-bottom px-2 align-items-center text-general" style="height:48px;">
            <div class="col px-2">
                <el-input v-model="search" placeholder="Buscar cedis" size="small" class="br-20" />
            </div>
        </div>
        <div class=" f-15">
            <div v-for="(info, idx) in buscadorCedis" :key="idx" class="row mx-0 p-1 d-middle border-bottom cr-pointer" style="min-height:110px;">
                <img class="border br-10 ml-3" height="92" width="92" :src="info.foto" alt="" />
                <div class="col pr-0 tres-puntos f-15">
                    <div class="row mx-0 f-600">
                        <p class="tres-puntos text-general">{{ info.nombre }}</p>
                    </div>
                    <div class="row mx-0 align-items-center">
                        <i class="icon-calendar-clock f-22 text-general" />
                        <!-- <i v-else class="icon-calendar-times f-18 text-dark" /> -->
                        <p class="tres-puntos text-general"> {{ formatearFecha(info.inicio, 'D MMM') }} - {{ formatearFecha(info.fin, 'D MMM') }}</p>
                        <p class="ml-auto mr-2 text-general f-600"> {{ validarFecha(info) }}</p>
                    </div>
                    <div class="row mx-0 align-items-center">
                        <el-tooltip class="item" effect="light" placement="bottom">
                            <div slot="content" class="text-center" style="width:140px;">
                                Productos añadidos por el cedis
                            </div>
                            <div class="br-10 text-general2 bg-light-f5 px-1 f-17" @click="prodIncluidos(info)">
                                <i class="icon-package-variant text-general f-20 mr-2" />
                                <span>{{ info.cant_productos }}</span>
                            </div>
                        </el-tooltip>
                        <el-tooltip class="item" effect="light" placement="bottom">
                            <div slot="content" class="text-center" style="width:140px;">
                                Número de ventas realizadas
                            </div>
                            <div v-if="true" class="br-10 text-white px-1 f-17 bg-general3 ml-2">
                                <i class="icon-comision f-15 mr-2" />
                                <span>{{ info.cant_vendido }}</span>
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
        <modalProdIncluidos ref="modalProdIncluidos" />
    </section>
</template>

<script>
import moment from 'moment'
import {mapGetters} from 'vuex'
import Promocion from '~/services/promociones/promociones'
export default {
    components: {
        modalProdIncluidos: () => import('../partials/modalProdIncluidos')
    },
    data(){
        return {
            search: null,
            id_eliminar: null
        }
    },
    computed:{
        ...mapGetters({
            cedis: 'promociones/promociones/cedis',
            detalle: 'promociones/promociones/detalle',

        }),
        buscadorCedis(){
            if(this.search !== null && this.search !== ''){
                return this.cedis.filter(el => el.nombre.toLowerCase().includes(this.search.toLowerCase()))

            }else{
                return this.cedis
            }
        },
    },
    methods: {
        agregarTienda(){
            this.$refs.modalAgregarTienda.toggle()
        },
        editarTienda(info){
            this.$refs.modalAgregarTienda.toggle(info)
        },
        eliminarTienda(id){
            this.id_eliminar = id
            this.$refs.modalEliminartienda.toggle()
        },
        prodIncluidos(info){
            this.$refs.modalProdIncluidos.toggle(info)
        },
        async eliminar(tipo){
            try {
                const enviar = {
                    id_eliminar: this.id_eliminar,
                    tipo: tipo
                }
                const {data} = await Promocion.eliminarTiendaPromocion(enviar)
                this.notificacion('Mensaje', 'Eliminó exitosamente', 'success')
                this.$store.dispatch('promociones/promociones/action_listar_promociones')
                const send = {
                    id_tienda: null,
                    id_promocion: this.detalle.id
                }
                await this.$store.dispatch('promociones/promociones/action_listar_detalle_promocion', send)
                this.$refs.modalEliminartienda.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        validarFecha(info){
            const hoy = moment()
            let mensaje = ''
            if (!hoy.isBetween(info.inicio, info.fin)){
                if (hoy.isAfter(info.fin)){
                    mensaje = 'Vencida'
                } else if (hoy.isBefore(info.inicio)){
                    mensaje = 'Fútura'
                }
            }else{
                mensaje = info.estado == 1 ? '' : 'Inactiva'
            }
            return mensaje
        }
    }
}
</script>
